import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import MianAppBar from '../main-app-bar/MianAppBar';
// import MainAppFooter from '../main-app-footer/MainAppFooter';
// import ReactNotification from 'react-notifications-component';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const AppLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const windowWidth = useRef(window.innerWidth);


  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      {/* <MianAppBar /> */}
      {/* <ReactNotification className={i18n.dir()} /> */}
      {children}
      {/* {renderNavLinks()} */}
      {/* <MainAppFooter /> */}
      <div title={t("main_app_bar_links.contact_us")}>
        <a
          href={`${windowWidth.current > 1025 ? "https://web.whatsapp.com/send?phone=9660502075083" : "https://api.whatsapp.com/send?phone=9660502075083"}`}
          target="_blank"
          className='whats-app'
          rel="noreferrer">
          <WhatsAppWidget className="test-whatsapp" phoneNumber="966550570058" />
        </a>
      </div>
    </div>
  );
};

export default AppLayout;
