import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Linkedin from "../../assets/imgs/icons/Linkedin.svg";
import Instagram from "../../assets/imgs/icons/Instagram.svg";
import Facebook from "../../assets/imgs/icons/Facebook.svg";
import YouTube from "../../assets/imgs/icons/youtube.png";
import XSocial from "../../assets/imgs/icons/x-social.png";
import TikTok from "../../assets/imgs/icons/tiktok.png";

import "./MainAppFooter.scss";
// import mainAppBarLinks from '../main-app-bar/mainAppBarLinks';
import Logo from "../../common/logo/Logo";

const MainAppFooter = () => {
  const { t } = useTranslation();

  const hanldeClick = (section) => () => {
    // history.push('/');
    //
    document.querySelector(`.${section}`).scrollIntoView();
  };

  const { pathname } = useLocation();
  const renderNavLinks = () => {
    return (
      <ul>
        {/* {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => ( */}
        {/* onClick={hanldeClick(section)} */}
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_vision.title")}:
            <span style={{ fontSize: "14px" }}>
              {" "}
              {t(" تتطلع الي تقديم خدمات مميزه بتعاملات رآقية")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_message.title")}:
            <span style={{ fontSize: "13px" }}>
              {" "}
              {t("السعي الي المثالية في تقديم الجديد والعصري")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* <li  >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("")}
            <span style={{ fontSize: '13px' }}>  {t("في عالم المال والآعمال")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li> */}
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_goals.title")}:
            <span style={{ fontSize: "14px", width: "101%" }}>
              {" "}
              {t("تحقيق رؤية عملآءنا التجارية وفق أعلي المعايير")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* ))} */}
      </ul>
    );
  };

  const renderNavComponies = () => {
    return (
      <ul>
        {/* {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => ( */}
        {/* onClick={hanldeClick(section)} */}
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_companies.sirusolutions_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_companies.sirubab_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_companies.siruhigin_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active-link" to={""} exact>
            {t("our_companies.sirutech_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* ))} */}
      </ul>
    );
  };
  return (
    <footer className="site-footer">
      <div className="site-footer-shape-bg"></div>
      <div className="container">
        <div className="site-footer__top">
          <div className="row">
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="footer-widget__column footer-widget__about">
                <div className="footer-widget__about-text-box">
                  <img
                    className="footer-widget__logo"
                    src="assets/images/icon/sirulogo.png"
                    alt=""
                  />
                  <p className="Noto-Regular footer-desc">
                    سيروا للحلول البرمجية هي إحدى المؤسسات التابعة لشركة سيروا
                    ماب وهى شركة سعودية ذات مسئولية محددة تأسست في العام 2023
                    وتضم عدة مؤسسات تجارية.
                  </p>
                </div>
              </div>
            </div>

            <div
              className=" col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="footer-widget__column footer-widget__meet-us clearfix footer-padding">
                <h3 className="footer-widget__title Noto-Regular">
                  عن الشركة{" "}
                </h3>
                <ul className="footer-widget__links-list list-unstyled clearfix">
                  <li>
                    <a className="Noto-Regular" href="#about">
                      الرؤية
                    </a>
                  </li>
                  <li>
                    <a className="Noto-Regular" href="#our-message">
                      الرسالة
                    </a>
                  </li>
                  <li>
                    <a className="Noto-Regular" href="#services">
                      الخدمات{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className=" col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="footer-widget__column footer-widget__contact">
                <h3 className="footer-widget__title Noto-Regular">
                  تواصل معنا
                </h3>
                <p className="Noto-Regular color-w">
                  العنوان: شارع وادى الصمان - حى العليا - الرياض
                </p>
                <div className="phone">
                  <a className="Noto-Regular color-w" href="">
                    الجوال : 502075083 966+
                  </a>
                </div>
                <div className="email">
                  <a className="Noto-Regular color-w" href="">
                    {" "}
                    البريد الإلكتروني : hello@sirusolutions.com
                  </a>
                </div>
                {/* <div
                  className='icon'>
                  <img src="assets/images/icon/Linkedin.png" alt="" />
                  <img src="assets/images/icon/Instagram.png" className='icon2' alt="" />
                  <img src="assets/images/icon/Facebook.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="row">
            <div className="col-xl-12">
              <div className="site-footer__bottom-inner">
                <p className="site-footer__bottom-text Noto-Regular">
                  جميع الحقوق محفوظة لشركة سيروا ماب 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainAppFooter;
