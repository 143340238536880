import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'
import one from '../../../assets/imgs/bg/ourvision.svg'
import oneMobile from '../../../assets/imgs/bg/ourvision.svg'

import visionTest from '../../../assets/imgs/bg/visionTest.svg'

import './ContactUs.scss'
import ContactForm from './ContactForm'


const ContactUs = () => {
    const { t } = useTranslation();

    const windowWidth = useRef(window.innerWidth);

    return (
        <section id="contact">

            <div className="scrolling-text-container">
                <div className="scrolling-text-inner" role="marquee">
                    <div className="scrolling-text">
                        <div className="scrolling-text-item Noto-Regular contact-text"> هل تبغي البدء الآن؟</div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا
                        </div>
                    </div>
                    <div className="scrolling-text">
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>
                        <div className="scrolling-text-item Noto-Regular contact-text contact-desc"
                        > هل تبغي البدء الآن؟
                        </div>

                        <div className="scrolling-text-item Noto-Regular contact-text contact-title"
                        > تواصل معنا</div>


                    </div>
                </div>
            </div>
            <div className="contact-contain">
                <div className="container-div">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-ms-12 contact-col"
                        >
                            <div className='info-div'>
                                <h3 className="Noto-Regular color-white" >لنبدأ دردشة سريعة</h3>
                                <div className='info'>
                                    <p className="Noto-Regular color-white" >تعرف علينا أكثر من خلال حجز اجتماع معنا
                                        علي زوم لمدة ١٥ دقيقية
                                    </p>
                                    <img src="assets/images/icon/Zoom.png" alt="" /> 
                                    <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3WIDNyCx2M9c68NF-n4IUaJxJGFdiRNXe2WW5CCeXW45NydrHW0Wah27XWWJ5k72XNIU4Rh3aR?gv=true" target='_blank' className="thm-btn main-menu-three__btn Noto-Regular reg-btn"
                                    >
                                        احجز اجتماع الآن</a>
                                </div>
                            </div>
                            <div className='main-div'>
                                <h3 className="Noto-Regular">أو تواصل معنا عبر:
                                </h3>
                                <div
                                    className='img-div'>
                                    <img src="assets/images/icon/call.png" alt="" />
                                    <a className="Noto-Regular color-white" href="">الجوال : +966 502075083</a>
                                </div>
                                <div
                                    className='img-div'>
                                    <img src="assets/images/icon/mail.png" alt="" />
                                    <a className="Noto-Regular color-white" href=""> البريد الإلكتروني :
                                    hello@sirusolutions.com
                                    </a>
                                </div>
                                {/* <div
                                    className='img-div'>
                                    <img src="assets/images/icon/massenger.png" alt="" />
                                    <a className="Noto-Regular color-white" href="">ماسينجر : Sirumap development
                                    </a>
                                </div> */}
                                <div
                                    className='img-div'>
                                    <img src="assets/images/icon/whatsapp.png" alt="" />
                                    <a className="Noto-Regular color-white" href="">الواتس : 9660502075083+</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5  col-md-6 col-ms-12">
                            <div className='main-div'>
                                <h3 className="Noto-Regular color-white" >أو دعنا نتواصل معك</h3>
                                <p className="Noto-Regular color-white" >قم بإدخال بياناتك و رسالتك و سيتم
                                    التواصل معكم خلال وقت قصير</p>
                                <ContactForm />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default ContactUs
